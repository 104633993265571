// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cBlkCoWUu", "RKyBZMAOB"];

const serializationHash = "framer-6sIgO"

const variantClassNames = {cBlkCoWUu: "framer-v-1keuon5", RKyBZMAOB: "framer-v-1ydxi17"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "cBlkCoWUu", "Variant 2": "RKyBZMAOB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cBlkCoWUu"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cBlkCoWUu", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1keuon5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cBlkCoWUu"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({RKyBZMAOB: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-wd7cf0"} layoutDependency={layoutDependency} layoutId={"eavqxUNx4"} style={{backgroundColor: "rgb(68, 204, 255)"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6sIgO.framer-1ib4kvb, .framer-6sIgO .framer-1ib4kvb { display: block; }", ".framer-6sIgO.framer-1keuon5 { height: 920px; overflow: hidden; position: relative; width: 1435px; }", ".framer-6sIgO .framer-wd7cf0 { flex: none; height: 277px; left: 53px; overflow: visible; position: absolute; top: 42px; width: 314px; }", ".framer-6sIgO.framer-v-1ydxi17 .framer-wd7cf0 { bottom: 61px; left: unset; right: 76px; top: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 920
 * @framerIntrinsicWidth 1435
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"RKyBZMAOB":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZ8bVFP7ch: React.ComponentType<Props> = withCSS(Component, css, "framer-6sIgO") as typeof Component;
export default FramerZ8bVFP7ch;

FramerZ8bVFP7ch.displayName = "componentFX2";

FramerZ8bVFP7ch.defaultProps = {height: 920, width: 1435};

addPropertyControls(FramerZ8bVFP7ch, {variant: {options: ["cBlkCoWUu", "RKyBZMAOB"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerZ8bVFP7ch, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})